import MDRouter from "@/service/router";
import { env } from "md-base-tools/env";
import domain from '@/service/domain';
import redirect from '@/service/redirect';
import { mapState } from "vuex";
import { getExtractCardDetail, getExtractCardRule, getMallOrderPayInfo, GetProbability, ExtractCardSubscribe } from '@/api/index';
import cont_match_reg from "@/utils/editor.js";
import { get } from '@/utils/apiBase.js';
import Error from "@@/common/Error.vue";
import CompRules from '@@/luckycards/popupRules.vue';
import CompOrder from '@@/luckycards/popupOrder.vue';
import CompEWM from '@@/luckycards/popupEWM.vue';
import { GoBack, postWXMessage } from "@/utils/tools";
import { getSchemeUrl } from "@/utils/toolsApi";
// import { PAGInit } from 'libpag';
import parseAPNG from 'apng-js';
import { updateUserInfo } from "@/utils/user";
import { showDialog } from 'vant';
import ClipboardJS from "clipboard";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Error,
    CompRules,
    CompOrder,
    CompEWM
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env,
      cards_id: '',
      cards_info: {},
      buttons: [],
      show_rules: false,
      rules_arr: [],
      show_order: false,
      order_type: 0,
      ifPayDown: false,
      order_item: {},
      luckycardsData: {},
      order_id: '',
      pay_amount: '',
      showError: false,
      showMessage: '',
      showEWM: false,
      loading: false,
      addressList: [],
      addressItem: {},
      ifChangeAddress: false,
      gif: false,
      color: '',
      huanhe: false,
      small: false,
      probabilityList: []
    };
  },
  created() {
    if (env.isWxApp()) {
      let query = {
        ...this.$route.query
      };
      const path = this.$route.path;
      let t = new Date().getTime();
      query.t = t;
      query.darkmode = true;
      this.$router.replace({
        path,
        query
      });
    }
  },
  async mounted() {
    const cards_id = this.$route.params.id ? this.$route.params.id.replace(".html", "") : "";
    this.cards_id = cards_id;
    this.sensors.track("LuckycardsDetail", {
      'card_id': cards_id
    });

    // 获取收货地址
    // this.getAddress()

    await this.getInfo();
    document.title = this.cards_info.name;
    document.addEventListener("visibilitychange", () => this.checkPay());
    this.openOrder();
    // let luckycardsData = sessionStorage.getItem('luckycardsData')
    // 在订单列表进入，默认打开下单弹层
    let luckycardsData = this.$route.query.luckycardsData;
    if (luckycardsData) {
      this.luckycardsData = JSON.parse(luckycardsData) || {};
      if (this.luckycardsData.order_id && this.luckycardsData.apply_pay_amount) {
        this.order_id = this.luckycardsData.order_id;
        this.pay_amount = this.luckycardsData.apply_pay_amount;
        // sessionStorage.removeItem('luckycardsData')
        let query = {
          ...this.$route.query
        };
        delete query.luckycardsData;
        const path = this.$route.path;
        let t = new Date().getTime();
        query.t = t;
        this.$router.replace({
          path,
          query
        });
        this.show_order = true;
      }
    }
    await this.$nextTick();
    this.setShare();
    if (env.isWxApp()) {
      postWXMessage({
        title: this.cards_info.name,
        imageUrl: this.cards_info.detail_image_url,
        url: `${domain.wap}/luckycards/fastsell/detail/${this.cards_id}`
      });
    }
    // var availableWidth = window.innerWidth;
    var availableHeight = window.innerHeight;
    if (typeof window.screen.availHeight === 'number') {
      //   availableWidth = Math.min(availableWidth, window.screen.availWidth);
      availableHeight = Math.min(availableHeight, window.screen.availHeight);
    }
    this.small = availableHeight < 750;
  },
  methods: {
    // 预约项目
    subscribeActivity() {
      if (!this.userId) {
        redirect.to('login');
        return;
      }
      if (this.cards_info.have_subscribe) return;
      ExtractCardSubscribe({
        id: this.cards_id
      }).then(res => {
        if (res.status == 0) {
          showDialog({
            title: '预约成功',
            message: '开售前10分钟将以短信、APP消息方式通知您，请及时关注',
            confirmButtonText: '知道啦',
            confirmButtonColor: '#00cba6'
          });
          this.cards_info.have_subscribe = true;
        }
      });
    },
    huanheFn() {
      if (!this.huanhe) {
        this.huanhe = true;
        setTimeout(() => {
          this.huanhe = false;
        }, 1000);
      }
    },
    jsonp({
      url,
      onData
    }) {
      const script = document.createElement('script');

      // 一、为了避免全局污染，使用一个随机函数名
      const cbFnName = `JSONP_PADDING_${Math.random().toString().slice(2)}`;
      // 二、默认 callback 函数为 cbFnName
      script.src = `${url}`;
      // 三、使用 onData 作为 cbFnName 回调函数，接收数据
      window[cbFnName] = onData;
      document.body.appendChild(script);
    },
    async createApngPlayer(url, ctx, options = {}) {
      const canvas = document.getElementById('pag');
      const imgBuffer = await this.getImgBuffer(url);
      const apng = parseAPNG(imgBuffer);
      Object.keys(options).forEach(key => {
        apng[key] = options[key];
      });
      canvas.width = apng.width;
      canvas.height = apng.height;
      apng.numPlays = 0;
      const player = await apng.getPlayer(ctx);
      return player;
    },
    async getImgBuffer(url) {
      const blob = await fetch(url).then(res => res.blob());
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      const res = await new Promise(resolve => {
        reader.onload = () => {
          resolve(reader.result);
        };
      });
      return res;
    },
    async getInfo() {
      this.loading = true;
      const {
        status,
        data,
        message
      } = await getExtractCardDetail({
        id: this.cards_id
      });
      this.loading = false;
      if (status == 0) {
        this.cards_info = data;
        this.buttons = data.buttons;
        get(`${data.detail_image_url}?x-oss-process=image/average-hue`).then(e => {
          this.color = e.RGB.slice(2);
        });
        setTimeout(() => {
          this.gif = true;
        }, 100);
        this.$nextTick(() => {
          if (this.$refs.editorBox) {
            this.$refs.editorBox.appendChild(cont_match_reg(`<p>${data.content}</p>`));
          }
        });
      } else {
        this.showError = true;
        this.showMessage = message || '';
      }
    },
    async showRules() {
      if (this.rules_arr && this.rules_arr.length) {
        this.show_rules = true;
      } else {
        await this.getRules();
        this.show_rules = true;
      }
    },
    async getRules() {
      const {
        data,
        status
      } = await getExtractCardRule({
        id: this.cards_id
      });
      if (status == 0) {
        this.rules_arr = data;
      }
    },
    async showOrder(item) {
      await updateUserInfo();
      await this.getProbabilityDetail();
      this.order_id = '';
      this.pay_amount = '';
      if (!this.userId) {
        redirect.to('login');
        return;
      }
      await this.$nextTick();
      this.show_order = true;
      this.order_item = item;
    },
    // 去卡册图鉴
    goUserCollect() {
      if (!this.userId) {
        redirect.to('login');
        return;
      }
      // eslint-disable-next-line
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/luckycards/fastsell/user_collect?id=${this.cards_id}`,
        webFun: () => {
          if (env.isWxApp()) {
            // eslint-disable-next-line
            wx.miniProgram.navigateTo({
              url: `/pages/webviewPages/content/content?url=${encodeURIComponent(domain.wap + '/luckycards/fastsell/user_collect?id=' + this.cards_id)}`
            });
          } else {
            this.$router.push({
              path: '/luckycards/fastsell/user_collect',
              query: {
                id: this.cards_id
              }
            });
          }
        }
      });
    },
    goContainer() {
      if (!this.userId) {
        return redirect.to('login');
      }
      // eslint-disable-next-line
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/luckycards/container`,
        webFun: () => {
          location.href = `${domain.wap}/luckycards/container`;
        }
      });
    },
    goBack() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "closeController",
        functionParams: "",
        callBack: () => {},
        webFun: () => {
          GoBack();
        }
      });
    },
    payDown(pay_id) {
      this.pay_id = pay_id;
      this.ifPayDown = true;
    },
    // 监听是否是在地址选择页面返回
    changeAddress(type) {
      this.ifChangeAddress = type;
    },
    checkPay() {
      const that = this;
      // 用户息屏、或者切到后台运行 （离开页面）
      if (document.visibilityState === "hidden") {
        console.log("hidden");
      }
      // 用户打开或回到页面
      if (document.visibilityState === "visible") {
        console.log("页面可见");
        if (that.ifPayDown) {
          setTimeout(that.getPayInfo(), 100);
        }
        if (that.ifChangeAddress) {
          this.$refs.CompOrder.updateAddress();
        }
      }
    },
    // 获取支付详情
    async getPayInfo() {
      if (!this.pay_id) return;
      // 微博内-微博支付完成会跳转拆包页，无需兜底二跳
      if (env.isWeibo()) return;
      this.ifPayDown = false;
      const {
        status,
        data
      } = await getMallOrderPayInfo({
        pay_id: this.pay_id
      });
      if (status == 0) {
        if (data.pay_status == 2) {
          sessionStorage.setItem('pay_id', this.pay_id);
          // 如果有group_id，跳转至组团详情
          if (data.card_info.group_id) {
            this.goGroupInfo(data.card_info.group_id, data.order_id);
          } else {
            // 否则跳转至拆包页面
            this.$router.push({
              path: '/luckycards/fastsell/prize',
              query: {
                id: this.cards_id
              }
            });
          }
        } else {
          if (data.order_id) {
            if (data.order_type == 4) {
              // 4是电商
              MDRouter.goMallOrderDetail(data.order_id);
            } else {
              // 其余众筹
              MDRouter.goProOrderDetail(data.order_id);
            }
          } else {
            location.href = `${domain.wap}/order/order_list`;
          }
        }
      } else {
        location.href = `${domain.wap}/order/order_list`;
      }
    },
    // 分享
    setShare() {
      let url = `${domain.wap}/luckycards/fastsell/detail/${this.cards_id}?utm_source=Othershare&utm_content=${this.userId}`;
      let share_option = {
        url,
        img: this.cards_info.detail_image_url,
        msg_des: `${this.cards_info.name} | 摩点 ${url}`,
        qq_des: this.cards_info.name,
        qq_share_url: `${url}`,
        qq_title: this.cards_info.name,
        qr_img: "",
        qzone_share_url: `${url}`,
        share_channel: "",
        share_url: `${url}`,
        sharelink_url: `${url}`,
        weibo_des: `${this.cards_info.name}${url}（分享自@摩点）`,
        weibo_share_url: `${url}`,
        weibo_title: "",
        weixin_share_url: `${url}`,
        wxTimeline_share_url: `${url}`,
        wx_des: `${this.cards_info.name} | 摩点 ${url}`,
        wx_title: this.cards_info.name,
        mini_programs_title: `${this.cards_info.name} | 摩点`,
        mina_share_img: this.cards_info.detail_image_url,
        mina_app_name: 'gh_7210ac3f1f08',
        mina_share_path: '/pages/index/index?nextUrl=pages/luckycards/detail/detail&id=' + this.cards_id
      };
      // eslint-disable-next-line no-undef
      userHeader.setShareInfo(share_option);
    },
    // 分享
    onShare() {
      if (!env.isWxApp()) {
        // eslint-disable-next-line no-undef
        userHeader.shareShow();
      }
    },
    // 展示二维码弹层
    showJoin() {
      this.showEWM = true;
    },
    // 默认打开弹窗
    openOrder() {
      if (sessionStorage.getItem('detailOrder')) {
        this.show_order = true;
      }
    },
    async getProbabilityDetail() {
      const {
        status,
        data,
        message
      } = await GetProbability({
        id: this.cards_id
      });
      if (status == 0) {
        this.probabilityList = data.list;
        console.log('this.probabilityList', this.probabilityList);
      } else {
        mdToast(message);
      }
    },
    // 去开团详情
    goGroupInfo(groupId = '', order_id = '') {
      const group_id = groupId != '' ? groupId : this.cards_info.group_info.id;
      let url = '';
      const VUE_env = process.env.VUE_APP_ENV;
      if (VUE_env && VUE_env != 'pre') {
        url = `md://mini_program?id=wx3b0aeea5ba10856c&name=gh_5638b10d3ccb&path=pages/luckycards/group/group?id=${this.cards_id}&group_id=${group_id}&order_id=${order_id}`;
      } else {
        url = `md://mini_program?id=wx6a3c68659310e879&name=gh_7210ac3f1f08&path=pages/luckycards/group/group?id=${this.cards_id}&group_id=${group_id}&order_id=${order_id}`;
      }
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: url,
        webFun: async () => {
          if (env.isWxApp()) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: `/pages/luckycards/group/group?id=${this.cards_id}&group_id=${group_id}&order_id=${order_id}`
            });
          } else {
            const param = {
              path: "/pages/index/index",
              query: `nextUrl=pages/luckycards/group/group&id=${this.cards_id}&group_id=${group_id}&order_id=${order_id}`
            };
            const option = {
              jump_wxa: JSON.stringify(param)
            };
            const url = await getSchemeUrl(option);
            if (url) {
              if (env.isWeibo()) {
                const dom = document.getElementsByClassName('share-btn-group')[0];
                const clipboard = new ClipboardJS(dom, {
                  text: () => url
                });
                clipboard.on('success', () => {
                  mdToast('内容已复制，请前往手机浏览器查看');
                  // 释放内存
                  clipboard.off('error');
                  clipboard.off('success');
                  clipboard.destroy();
                });
                clipboard.on('error', () => {
                  // 不支持复制
                  mdToast({
                    type: 'waning',
                    message: '该浏览器不支持自动复制'
                  });
                  // 释放内存
                  clipboard.off('error');
                  clipboard.off('success');
                  clipboard.destroy();
                });
                // clipboard.onClick(e)
              } else {
                location.href = url;
              }
            }
          }
        }
      });
    }
  }
};