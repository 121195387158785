import Cookies from 'js-cookie';
import MDRouter from "@/service/router";
import domain from '@/service/domain';
import { mapState } from "vuex";
import redirect from '@/service/redirect';
import { env } from "md-base-tools/env";
import { postOrder } from "@/utils/apiBase.js";
import { MallPreviewOrder, CreateOrder, getScheme, OrderResetPay, CreateProjectOrder, CheckHasBought, getDefaultAddress, ExtractCardOrderRules } from "@/api/index";
import { throttle } from "@/utils/tools";
import { mdToast } from '../../utils/tools';
export default {
  props: ["show_order", "cards_id", "cards_info", "item", "name", "pay_amount", "order_id", "probabilityList"],
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    showOrder: {
      get() {
        return this.show_order;
      },
      set(value) {
        this.$emit("update:show_order", value);
      }
    },
    pay_resource() {
      if (this.env.isWeixin() && this.pay_type == "weixin_mina") {
        return "weixin";
      } else if (this.env.isWeibo()) {
        return "weibo";
      } else if (this.origin_type == 'project' && this.pay_type == 'weixin_mina') {
        return 'weixin';
      } else if (!(this.env.isModianIos() || this.env.isModianAndroid()) && !(sessionStorage.getItem("miniProgram") === "weixin" || this.env.isWxApp()) && this.pay_type == "weixin_mina") {
        return "weixin";
      } else {
        return this.pay_type;
      }
    },
    origin_type() {
      let type = 'product'; // 电商
      if (this.cards_info.pro_id != 0 && this.cards_info.reward_id != 0) {
        type = 'project'; // 众筹
      }
      return type;
    }
  },
  data() {
    return {
      iframe: false,
      loading: false,
      html: "",
      env,
      num: 1,
      amount: "",
      iframeUrl: location.origin + "/luckycards/fastsell/openhtml",
      agreementChecked: false,
      pay_type: "weixin_mina",
      // weixin_mina/alipay
      shops: [],
      pay_id: "",
      payOrderId: "",
      sitem: {},
      showLoading: false,
      addressItem: {},
      // 地址
      rulesList: [{
        context: '1. 未成年人请勿下单。'
      }, {
        context: '2. 该商品一经售出即在线拆包知晓具体款式，不支持退换。'
      }, {
        context: '3. 抽卡类产品具有随机性，可能出现重复商品，请您理性购买。'
      }, {
        context: '4. 因限于商品本身制作工艺技术，卡牌商品会存在轻微蹭色、溢色、染色不均、轻微划痕、轻微卷曲等均属于正常情况，符合产品质量标准，并非属于产品质量问题，不支持退换。'
      }] // 下单规则
    };
  },
  methods: {
    jumpA() {
      const url = "https://m.modian.com/about/about_index/3/24";
      MDRouter.Link(url);
    },
    close() {
      this.showOrder = false;
    },
    async open() {
      this.getOrderRules();
      if (sessionStorage.getItem('detailOrder')) {
        if (sessionStorage.getItem('address')) {
          this.addressItem = JSON.parse(sessionStorage.getItem('address'));
        } else {
          this.getAddress();
        }
        let option = JSON.parse(sessionStorage.getItem('detailOrder'));
        this.num = option.num;
        this.amount = option.amount;
        this.agreementChecked = option.agreementChecked;
        this.pay_resource = option.pay_resource;
        this.sitem = JSON.parse(option.sitem);
        sessionStorage.removeItem('detailOrder');
        sessionStorage.removeItem('address');
        this.$emit("changeAddress", false);
      } else {
        // 如果是众筹，获取默认收货地址
        if (this.origin_type == 'project') {
          this.showLoading = true;
          await this.getAddress();
          this.showLoading = false;
        }
        this.sitem = this.item;
        this.num = 1;
        if (this.pay_amount && this.order_id) {
          this.amount = this.pay_amount;
        } else {
          if (this.origin_type == 'project') {
            this.amount = this.sitem.sale_price;
          } else {
            this.previewOrder(true);
          }
        }
        // 检测是否购买过
        this.checkBtn();
      }
      setTimeout(() => {
        document.querySelectorAll("iframe")[0].src = location.origin + "/luckycards/fastsell/openhtml";
      }, 100);
    },
    // 获取提示规则
    getOrderRules() {
      ExtractCardOrderRules({
        id: this.cards_id
      }).then(res => {
        if (res.status == 0 && res.data.list && res.data.list.length) {
          this.rulesList = res.data.list;
        }
      });
    },
    addNum() {
      if (this.sitem.type_code == 1 && this.num < 20) {
        this.num += 1;
        if (this.origin_type == 'project') {
          this.amount = (Number(this.sitem.sale_price) * this.num).toFixed(2);
        } else {
          this.previewOrder(true);
        }
      }
    },
    reduceNum() {
      if (this.sitem.type_code == 1 && this.num > 1) {
        this.num -= 1;
        if (this.origin_type == 'project') {
          this.amount = (Number(this.sitem.sale_price) * this.num).toFixed(2);
        } else {
          this.previewOrder(true);
        }
      }
    },
    changePayType(type) {
      this.pay_type = type;
    },
    async previewOrder(tryReplay) {
      const {
        status,
        data
      } = await MallPreviewOrder({
        sku_id: JSON.stringify([{
          sku_id: String(this.sitem.sku_id),
          num: String(this.num)
        }])
      });
      if (status == 0) {
        if (data.amount) {
          this.amount = data.amount;
          this.shops = data.shops;
        } else {
          this.amount = "--";
        }
      } else {
        if (tryReplay) {
          setTimeout(() => {
            this.previewOrder(false);
          }, 100);
        } else {
          this.amount = "--";
        }
      }
    },
    subOrder: throttle(async function () {
      if (this.origin_type == 'project' && JSON.stringify(this.addressItem) == '{}') {
        return mdToast("请选择卡片发货地址");
      }
      if (this.order_id && this.pay_amount) {
        this.getResetPay();
      } else {
        this.createOrder();
      }
    }, 50),
    createOrder: throttle(async function () {
      this.loading = true;
      if (!this.agreementChecked) {
        mdToast("请阅读并同意《服务协议》");
        this.loading = false;
        return;
      }
      if (!Number(this.amount)) {
        mdToast("参数错误");
        this.loading = false;
        return;
      }
      let orderRes = {};
      const utmarr = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_term', 'utm_content'];
      const utmobj = {};
      for (let key of utmarr) {
        if (sessionStorage.getItem(key)) {
          utmobj[key] = sessionStorage.getItem(key);
        }
      }
      if (this.origin_type == 'project') {
        // 众筹下单
        let option = {
          pro_id: this.cards_info.pro_id,
          rew_id: this.cards_info.reward_id,
          pay_resource: this.pay_resource,
          total: this.num,
          money: this.amount,
          extract_card_type: this.sitem.type_code,
          extract_card_id: this.cards_id,
          address_id: this.addressItem.id
        };
        Object.assign(option, {
          utm: JSON.stringify(utmobj)
        });
        orderRes = await CreateProjectOrder(option);
        if (orderRes.status == 0) {
          this.pay_id = orderRes.data.pay_id;
        } else {
          mdToast(orderRes.message || '下单失败');
          this.loading = false;
          return false;
        }
      } else {
        // 电商下单
        let option = {
          pay_resource: this.pay_resource,
          total_amount: this.amount,
          shops: JSON.stringify(this.shops)
        };
        Object.assign(option, {
          utm: JSON.stringify(utmobj)
        });
        orderRes = await CreateOrder(option);
        if (orderRes.status !== 0) {
          mdToast(orderRes.message);
          this.loading = false;
          return false;
        }
        this.pay_id = orderRes.data.pay_id;
      }
      this.toPay();
    }, 50),
    sendOrder: throttle(function () {
      if (!this.agreementChecked) {
        mdToast("请阅读并同意《服务协议》");
        this.loading = false;
        return;
      }
      const _this = this;
      var payParams = {
        url: "/mall/order/sendpay",
        method: "post",
        props: {
          pay_id: _this.pay_id,
          pay_resource: this.pay_resource
        }
      };
      postOrder(payParams.url, payParams.props).then(res => {
        if (res.status == 0) {
          this.payOrderId = res.data.payOrderId;
          if (this.pay_resource === "alipay") {
            if (res.data.dataType == "URL" && !this.env.isModianAndroid()) {
              window.location.href = res.data.data;
            } else {
              if (this.env.isModianIos() || this.env.isModianAndroid()) {
                this.iframe = true;
                setTimeout(() => {
                  let iframeWin = document.querySelectorAll("iframe")[0].contentWindow;
                  document.querySelectorAll("iframe")[0].style.cssText += "position: fixed;display: block;border: 0px;top: 44px;left: 0px;width: 100vw;z-index: -999;height: 0;";
                  iframeWin.postMessage({
                    data: res.data.data,
                    type: "alpay",
                    dataType: res.data.dataType
                  }, "*");
                }, 100);
              } else {
                if (res.data.dataType == "URL") {
                  window.location.href = res.data.data;
                } else if (res.data.dataType == "FORMDATA") {
                  this.html = res.data.data;
                  setTimeout(() => {
                    document.forms[0].submit();
                  });
                }
              }
            }
          } else if (res.data.weixin_mina_path && (this.env.isModianIos() || this.env.isModianAndroid())) {
            // eslint-disable-next-line
            graft.app({
              functionName: "openController",
              functionParams: res.data.weixin_mina_path //  + "&program_type=2"
            });
          } else if (res.data.weixin_mina_path_pages || !env.isWeixin() && !env.isWeibo() && this.pay_resource == "weixin_mina") {
            // eslint-disable-next-line camelcase
            const get_scheme_data = res.data.weixin_mina_path_pages.split("?");
            getScheme({
              jump_wxa: JSON.stringify({
                path: get_scheme_data[0],
                query: get_scheme_data[1]
              })
            }).then(e => {
              if (e.status == 0) {
                location.href = e.data;
              } else {
                mdToast(e.message);
              }
            });
          } else if (this.env.isWeixin() || this.env.isWxApp()) {
            if (sessionStorage.getItem("miniProgram") === "weixin" || this.env.isWxApp()) {
              // eslint-disable-next-line no-undef
              wx.miniProgram.navigateTo({
                url: "/pages/payPages/pay_h5/pay_h5?pay_id=" + res.data.payOrderId
              });
            } else {
              const json = {
                appId: res.data.data.appid || res.data.data.appId,
                timeStamp: res.data.data.timestamp || res.data.data.timeStamp,
                nonceStr: res.data.data.noncestr || res.data.data.nonceStr,
                package: res.data.data.package || res.data.data.packAge,
                signType: res.data.data.signtype || res.data.data.signType,
                paySign: res.data.data.paysign || res.data.data.paySign
              };
              this.callWeixinPay(json);
            }
          } else {
            window.location.href = res.data.data;
          }
          this.$emit("payDown", _this.pay_id);
        } else {
          if (res.status == 10000) {
            return redirect.to('login');
          } else {
            mdToast(res.message || "支付失败");
          }
        }
        this.loading = false;
        this.showOrder = false;
      });
    }, 50),
    callWeixinPay(json) {
      const loadStatusData = setInterval(() => {
        // eslint-disable-next-line no-undef
        if (WeixinJSBridge !== undefined) {
          clearInterval(loadStatusData);
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", json, res => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.log(res.err_msg);
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.PaySuccessBack();
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              mdToast("取消支付");
            } else {
              mdToast(res.err_msg);
            }
          });
        }
      }, 300);
    },
    async getResetPay() {
      if (!this.agreementChecked) {
        mdToast("请阅读并同意《服务协议》");
        return;
      }
      const res = await OrderResetPay({
        order_id: this.order_id,
        pay_resource: this.pay_resource
      });
      if (res.status !== 0) {
        mdToast(res.message);
        return false;
      }
      this.pay_id = res.data.pay_id;
      this.toPay();
      // this.sendOrder();
    },
    toPay() {
      // 小程序跳转至小程序支付
      if (sessionStorage.getItem("miniProgram") === "weixin" || this.env.isWxApp()) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: "/pages/payPages/pay_h5/pay_h5?pay_id=" + this.pay_id + "&cards_id=" + this.cards_id + "&type=luckycards"
        });
        this.loading = false;
      } else {
        this.sendOrder();
      }
    },
    PaySuccessBack() {
      sessionStorage.setItem("pay_id", this.pay_id);
      this.$router.push({
        path: "/luckycards/fastsell/prize",
        query: {
          id: this.cards_id
        }
      });
    },
    // 检测是否买过
    checkBtn() {
      CheckHasBought().then(res => {
        if (res.status == 0) {
          this.agreementChecked = res.data;
        } else {
          this.agreementChecked = false;
        }
      });
    },
    // 切换地址
    goToAddressList() {
      let option = {
        amount: this.amount,
        num: this.num,
        pay_resource: this.pay_resource,
        agreementChecked: this.agreementChecked,
        sitem: JSON.stringify(this.sitem)
      };
      sessionStorage.setItem('detailOrder', JSON.stringify(option));
      this.$emit("changeAddress", true);
      // eslint-disable-next-line
      graft.app({
        functionName: "chooseAddress",
        functionParams: {},
        callBack: e => {
          if (e.errorCode == 0) {
            this.addressItem = e.data;
          } else {
            mdToast('请升级app版本');
          }
        },
        webFun: () => {
          location.href = `${domain.wap}/address/address_list?redirectUrl=${encodeURIComponent(location.origin + location.pathname)}`;
        }
      });
      // this.$router.push(`/address/address_list?redirectUrl=${encodeURIComponent(location.origin + location.pathname)}`)
    },
    // 获取默认地址接口
    async getAddress() {
      const res = await getDefaultAddress({
        user_id: this.userId
      });
      if (res.status == 0) {
        this.addressItem = res.data;
      } else {
        this.addressItem = {};
      }
    },
    updateAddress() {
      if (sessionStorage.getItem('address')) {
        this.addressItem = JSON.parse(sessionStorage.getItem('address'));
      } else {
        this.getAddress();
      }
      this.$emit("changeAddress", false);
    },
    tabThailand() {
      window.location.href = 'https://m.hi-mystery.com/luckycards/detail?id=' + this.cards_id + '&fromcn=1&loginToken=' + Cookies.get('MDUSERTOKEN');
    }
  }
};