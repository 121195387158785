export default {
  props: ['show_rules', 'rules_arr'],
  computed: {
    showRules: {
      get() {
        return this.show_rules;
      },
      set(value) {
        this.$emit('update:show_rules', value);
      }
    }
  }
};